<template>
  <div v-if="model">
    <img :src="model.fileName" class="news-bg" alt="Haber" />
    <img :src="model.fileName" class="news-bg bg-blur" alt="Haber" />
    <div class="newsMain">
      <div class="news-title-logo wow fadeInDown" data-wow-delay="0.3s">
        <img src="/images/bg/recor-logo.png" alt="İçerik" />
      </div>
      <div class="news-text wow fadeInUp" data-wow-delay="1.2s">
        <span v-html="model.description"></span>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
import store from "../store/index";
new WOW().init();
export default {
  name: "gunnies",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, this.model.displaySecond * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/animate/animate.css";
@import "../assets/scss/news.scss";
.newsMain {
  .news-title-logo {
    display: block;
    text-align: center;
    z-index: 999;
    position: relative;
  }
  .news-text {
    background-image: url("/images/bg/recor-bg.png");
    margin-top: -44px;
  }
}
</style>
